import settings from '@/settings'

export default {
  methods: {
    setPageTitle (title) {
      const pageTitle = title
        ? `${title}${settings.app.title.separator}${settings.app.title.defaultTitle}`
        : settings.app.title.defaultTitle

      this.$nextTick(() => {
        window.document.title = pageTitle
      })
    }
  }
}
