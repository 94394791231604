import { mapActions, mapState } from 'vuex'

import { extractProductCategory } from '@/transformers'
import AnalyticsService from '@/services/analytics'

export default {
  computed: {
    ...mapState('cart', ['cartData'])
  },
  methods: {
    ...mapActions('cart', ['addToCart', 'saveCartItemEntryAdditionalData']),
    ...mapActions('analytics', ['populateAddProductsToCartAnalytics', 'getProductDataFromAlgoliaBySku']),
    async addProductToCart ({
      productData: product,
      addToCartQty: qty,
      position,
      pageName,
      indexName,
      queryID,
      catalogOriginalTitle
    }) {
      const isClearanceProduct = !!product.clearancePrice
      const { cartItemEntryNumber, hasShortage, inventoryQty } = await this.addToCart({
        code: product.sku,
        quantity: qty,
        isClearance: isClearanceProduct
      })
      const isProductCanBeAddedToCart = !(hasShortage && isClearanceProduct)
      const qtyAddedToCart = hasShortage && isClearanceProduct ? inventoryQty : qty

      if (isProductCanBeAddedToCart) {
        this.productEntryData = {
          ...product,
          quantity: qtyAddedToCart
        }
      }

      if (hasShortage) {
        this.inventoryData = {
          inventoryQty,
          isClearance: isClearanceProduct,
          productTitle: product.title
        }
        this.showModalInventory = true
      } else {
        this.showModalCart = true
      }

      if (AnalyticsService.googleEnabled && isProductCanBeAddedToCart) {
        const cartItemEntryAdditionalData = {
          entryNumber: cartItemEntryNumber,
          list: pageName,
          position,
          indexName,
          queryID,
          catalogOriginalTitle,
          brand: product.brand,
          category: product.groupedCategories?.length
            ? extractProductCategory(product.groupedCategories)
            : ''
        }
        if (!cartItemEntryAdditionalData.category || !cartItemEntryAdditionalData.brand) {
          const { category, brand } = await this.getProductDataFromAlgoliaBySku(product.sku)
          cartItemEntryAdditionalData.category = category
          cartItemEntryAdditionalData.brand = brand
        }
        this.saveCartItemEntryAdditionalData(cartItemEntryAdditionalData)
        this.populateAddProductsToCartAnalytics([{
          sku: product.sku,
          catalogId: product.catalogId,
          quantity: qtyAddedToCart,
          brand: product.brand,
          category: cartItemEntryAdditionalData.category,
          title: product.title,
          position,
          pageName
        }])
      }
    },
    closeInventoryModal () {
      this.showModalInventory = false
      if (!this.inventoryData.isClearance && this.inventoryData.inventoryQty !== 0) this.showModalCart = true
      this.inventoryData = null
    },
    closeCartModal () {
      this.productEntryData = null
      this.showModalCart = false
    }
  },
  data () {
    return {
      productEntryData: null,
      inventoryData: null,
      showModalCart: false,
      showModalInventory: false
    }
  }
}
