<template>
  <div class="payment-type">
    <div class="payment-type__method">
      <img v-if="data.cardTypeCode"
        :src="`/images/payment/${data.cardTypeCode.toLowerCase()}.svg`"
        :alt="`${data.cardTypeCode}`"
        loading="lazy"
        class="payment-type__img"
      />
      <i v-else class="icon-credit-card payment-type__icon"></i>
      <span class="payment-type__card-number">{{ data.cardNumber }}</span>
      <span class="payment-type__card-expiration">{{ data.expirationDate}}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PaymentCard',
  props: {
    data: Object
  }
}
</script>
