<template>
  <Modal
    :isLoading="!isInitialized"
    :showModal="showModal"
    :isOverlayClickable="isInitialized"
    @close="$emit('close')"
    class="modal-cart"
  >
    <template v-slot:header v-if="isInitialized">
      <div>
        <i class="modal-cart__check icon-check-circle"></i>
        Added To Cart
      </div>
    </template>
    <template v-slot:body v-if="isInitialized">
      <div
        v-for="entry in orderData.entries"
        :key="entry.product.sku"
        class="modal-cart__product modal-product"
      >
        <div class="modal-product__img-container">
          <img class="modal-product__img" :src="entry.product.image.src" :alt="entry.product.image.title" loading="lazy" />
        </div>
        <div class="modal-product__desc">
          <h4 class="modal-product__title text-h4" v-html="entry.product.title"></h4>
          <p class="modal-product__brand" v-html="`Catalog ID: ${entry.product.catalogId}`"></p>
          <p class="modal-product__quantity">Quantity: {{ entry.quantity }}</p>
          <div class="modal-product__price">
            <div class="modal-product__price-line" v-if="entry.quantity > 1">
              <ProductPrice :productData="entry.product" />
              <span class="modal-product__price-unit">Each</span>
            </div>
            <div class="modal-product__price-line">
              <ProductPrice :productData="calculatePriceDataForAddedQuantity(entry.product, entry.quantity)" />
              <span class="modal-product__price-unit">Total</span>
            </div>
            <div v-if="!entry.product.isPurchasable" class="modal-product__price-line">
              <span class="modal-product__text-orange">Item not available and was not added to cart</span>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-cart__total" v-if="cartData">
        <p>
          Cart subtotal ({{ `${cartData.totalItems} ${cartData.totalItems > 1 ? 'items' : 'item'}` }}):
          {{ formatPrice(cartData.totalPrice) }}
        </p>
      </div>
    </template>
    <template v-slot:footer v-if="isInitialized">
      <div class="modal-cart__actions">
        <button type="button" @click="$emit('close')" class="button button--primary">
          Continue Shopping
        </button>
        <router-link to="/cart" type="button" class="button button--outlined">
          View Cart & Checkout
        </router-link>
      </div>
    </template>
  </Modal>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import FormatMixin from '@/mixins/FormatMixin'
import UtilityMixin from '@/mixins/UtilityMixin'
import Modal from '@/components/modal/Modal'
import ProductPrice from '@/components/ProductPrice'

export default {
  name: 'ModalReorder',
  components: {
    Modal,
    ProductPrice
  },
  mixins: [FormatMixin, UtilityMixin],
  props: {
    orderData: {
      type: Object,
      requred: true
    },
    showModal: Boolean,
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('cart', ['cartData'])
  },
  methods: {
    ...mapActions('orders', ['sendReorder']),
    calculatePrice (price, quantity) {
      return price ? (price * quantity).toFixed(2) : null
    },
    calculatePriceDataForAddedQuantity (product, quantity) {
      return {
        clearancePrice: this.calculatePrice(product.clearancePrice, quantity),
        employeePrice: this.calculatePrice(product.employeePrice, quantity),
        listPrice: this.calculatePrice(product.listPrice, quantity),
        affiliatePrice: this.calculatePrice(product.affiliatePrice, quantity),
        discountPrice: this.calculatePrice(product.discountPrice, quantity)
      }
    }
  },
  async created () {
    await this.sendReorder(this.orderData.id)
    this.isInitialized = true
  },
  data () {
    return {
      isInitialized: false
    }
  }
}
</script>
