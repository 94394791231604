<template>
  <div>
    <h3 class="text-h3">Order Details</h3>
    <div class="order-details__header">
      <div class="order-details__date">
        Ordered on <span>{{ formatDate(orderDetailsData.created) }}</span>
      </div>
      <div class="order-details__number">
        Order Reference # <span>{{ orderDetailsData.id }}</span>
      </div>
    </div>
    <ul class="order-details__info">
      <li class="order-details__info-item">
        <div class="order-details__info-title">Shipping Address</div>
        <div class="order-details__address">
          <AddressItem :data="orderDetailsData.shippingAddress" />
          <ul class="order-details__address-additional-fields address-item">
            <li class="address-item__line">{{ orderDetailsData.customerUid }}</li>
          </ul>
        </div>
        <div v-if="orderDetailsData.deliveryMode" class="order-details__info-block">
          <div class="order-details__info-title">Shipping Method</div>
          <div class="order-details__address">
            <ul>
              <li>{{ orderDetailsData.deliveryMode.title }}</li>
              <li>{{ orderDetailsData.deliveryMode.description }}</li>
              <li>{{ formatPrice(orderDetailsData.deliveryMode.price) }}</li>
            </ul>
          </div>
        </div>
      </li>
      <li class="order-details__info-item">
        <div class="order-details__info-title">Payment Method</div>
        <PaymentCard :data="orderDetailsData.paymentDetails"/>
      </li>
      <li class="order-details__info-item">
        <div class="order-details__info-title">Order Summary</div>
        <ul class="summary__list">
          <li class="summary__item-wrapper">
            <div class="summary__item">
              <span>Number of Items:</span>
              <span>{{ orderDetailsData.totalItems }}</span>
            </div>
            <div class="summary__item">
              <span>Subtotal:</span>
              <span>{{ formatPrice(orderDetailsData.subTotal) }}</span>
            </div>
            <div class="summary__item">
              <span>Shipping:</span>
              <span>{{ formatPrice(orderDetailsData.deliveryCost) }}</span>
            </div>
          </li>
          <li class="summary__item-wrapper summary__item-wrapper--big" v-if="orderDetailsData.totalDiscounts">
            <div class="summary__item">
              <span>Discounts & Promos:</span>
              <span>- {{ formatPrice(orderDetailsData.totalDiscounts) }}</span>
            </div>
          </li>
          <li class="summary__item-wrapper">
            <div class="summary__item">
              <span>Total before tax:</span>
              <span>{{ formatPrice(orderDetailsData.totalPrice) }}</span>
            </div>
            <div class="summary__item">
              <span>Estimated tax:</span>
              <span>{{ formatPrice(orderDetailsData.totalTax) }}</span>
            </div>
          </li>
          <li class="summary__item-wrapper">
            <div class="summary__item text-h4">
              <span>Total:</span>
              <span>{{ formatPrice(orderDetailsData.totalPriceWithTax) }}</span>
            </div>
          </li>
        </ul>
        <div v-if="isReorderEnabled" class="order-details__summary-actions">
          <button
            @click="showModalReorder = true"
            :disabled="!orderDetailsData.entries.some(entry => entry.product.isPurchasable)"
            class="button button--primary"
          >Order Again</button>
        </div>
      </li>
    </ul>
    <div class="order-details__products">
      <template
        v-for="(groupByStatus, groupStatus) in productGroups"
      >
        <table
          v-for="(groupByTrackingId, trackingId) in groupByStatus"
          :key="trackingId + groupStatus"
          class="products__list table"
        >
          <thead class="products__header">
            <tr>
              <th class="text-left">
                <span class="products__status">
                  <i class="icon" :class="getShippingStatus(groupStatus).iconClass"></i>
                  <span>{{ getShippingStatus(groupStatus).title }}</span>
                </span>
              </th>
              <template v-if="!isMobile">
                <th class="text-left">
                  <span>Product Info</span>
                </th>
                <th class="text-center">Quantity Ordered</th>
                <th class="text-center">Quantity Shipped</th>
                <th>
                  <div class="products__ship-date-title">
                    <span>Estimated Ship Date</span>
                    <i
                      class="icon-question-circle products__ship-date-icon"
                      v-tooltip="'These dates are estimated; once shipped,<br/> view tracking number for more details,<br/> or contact us for support'"
                    />
                  </div>
                </th>
                <th class="text-left">Price</th>
                <th></th>
              </template>
            </tr>
          </thead>
          <tbody class="products__body">
            <tr v-if="trackingId !== 'noTrackingId'" class="sub-header">
              <td colspan="6">
                <span>
                  Tracking:
                  <span v-html="trackingId" class="sub-header__tracking-id"></span>
                </span>
              </td>
            </tr>
            <tr
              v-for="entry in groupByTrackingId"
              :key="entry.product.sku"
              class="products__item"
            >
              <template v-if="!isMobile">
                <td class="products__image-col">
                  <a
                    @click.prevent="handleProductLinkClick(entry.product.link)"
                    :href="entry.product.link"
                  >
                    <img
                      :src="entry.product.image.previewSrc"
                      :alt="entry.product.image.title"
                      loading="lazy"
                      class="products__item-img"
                    />
                  </a>
                </td>
                <td class="products__descr-col">
                  <div class="products__item-info">
                    <div class="products__item-title">
                      <a
                        @click.prevent="handleProductLinkClick(entry.product.link)"
                        v-html="entry.product.title"
                        :href="entry.product.link"
                      ></a>
                    </div>
                    <div class="products__item-id">
                      Catalog ID: {{ entry.product.catalogId }}
                    </div>
                    <div class="products__item-desc" v-html="entry.product.descriptionShort"></div>
                  </div>
                </td>
                <td class="products__item-qty text-center">{{ entry.quantity }}</td>
                <td class="products__item-qty text-center">{{ extractQuantityShipped(entry) }}</td>
                <td class="products__item-qty text-center">
                  <ul v-if="entry.shipDates">
                    <li
                      v-for="(shipQuantity, shipDate) in entry.shipDates"
                      :key="shipDate"
                      class="products__ship-date"
                    >{{ formatDate(shipDate, 'MM/DD/YYYY') }}</li>
                  </ul>
                  <span v-else>-</span>
                </td>
                <td class="products__item-price">
                  <div class="product-line__price--single cart-product__price--single">
                    <p class="product-price">
                      <span
                        class="product-price__text"
                        :class="{'product-price__text--discount': entry.adjustedPrice && (entry.basePrice > entry.adjustedPrice)}"
                      >{{ formatPrice(entry.adjustedPrice || entry.basePrice) }}
                      </span>
                      <span
                        v-if="entry.adjustedPrice && (entry.basePrice > entry.adjustedPrice)"
                        class="product-price__text product-price__text--base"
                      >{{ formatPrice(entry.basePrice)  }}</span>
                      <span class="product-price__text">Each</span>
                    </p>
                  </div>
                  <div class="products__item-total">
                    <p class="product-line__price cart-product__price">
                      <span
                        class="product-price__text"
                        :class="{'product-price__text--discount': entry.totalPriceWithoutDiscount > entry.totalPrice}"
                      >{{ formatPrice(entry.totalPrice) }}
                      </span>
                      <span
                        v-if="entry.totalPriceWithoutDiscount > entry.totalPrice"
                        class="product-price__text product-price__text--base"
                      >{{ formatPrice(entry.totalPriceWithoutDiscount)  }}</span>
                      <span class="product-price__text">Total</span>
                    </p>
                  </div>
                </td>
                <td v-if="isReorderEnabled" class="products__cart-button-col">
                  <button
                    @click="onAddProductToCartClick(entry)"
                    :disabled="!entry.product.isPurchasable"
                    class="button button--primary button--add-to-cart"
                  >
                    <i class="icon-shopping-cart"></i>
                    Add to cart
                  </button>
                </td>
              </template>
              <div class="cart-product" v-else>
                <div class="cart-product__img-wrap">
                  <img
                    :src="entry.product.image.previewSrc || '/img-placeholder.svg'"
                    :alt="entry.product.image.title"
                    loading="lazy"
                    class="cart-product__img"
                  />
                </div>
                <div class="cart-product__description">
                  <a
                    @click.prevent="handleProductLinkClick(entry.product.link)"
                    v-html="entry.product.title"
                    :href="entry.product.link"
                    class="product-line__title cart-product__title"
                  ></a>
                  <p class="cart-product__sku">
                    Catalog ID: {{ entry.product.catalogId }}
                  </p>
                  <div class="products__item-desc" v-html="entry.product.descriptionShort"></div>

                  <div class="cart-product__qty-wrap">
                    <p class="cart-product__qty-text">
                      <span>{{ `Quantity Ordered: ${entry.quantity}` }}</span>
                    </p>
                    <p class="cart-product__qty-text">
                      <span>{{ `Quantity Shipped: ${extractQuantityShipped(entry)}` }}</span>
                    </p>
                  </div>

                  <div class="cart-product__qty-wrap">
                    <i
                      class="icon-question-circle"
                      v-tooltip="'These dates are estimated; once shipped,<br/> view tracking number for more details,<br/> or contact us for support'"
                    />
                    <span> Estimated Ship Date: </span>
                    <span v-if="entry.shipDates">
                      <span
                        v-for="(shipQuantity, shipDate) in entry.shipDates"
                        :key="shipDate"
                        class="products__ship-date"
                      >{{ formatDate(shipDate, 'MM/DD/YYYY') }}</span>
                    </span>
                    <span v-else>-</span>
                  </div>

                  <div class="cart-product__price-wrap">
                    <div class="product-line__price--single cart-product__price--single">
                      <p class="product-price">
                        <span
                          class="product-price__text"
                          :class="{'product-price__text--discount': entry.basePrice > entry.adjustedPrice}"
                        >{{ formatPrice(entry.adjustedPrice) }}
                        </span>
                        <span
                          v-if="entry.basePrice > entry.adjustedPrice"
                          class="product-price__text product-price__text--base"
                        >{{ formatPrice(entry.basePrice)  }}</span>
                        <span class="product-price__text">Each</span>
                      </p>
                    </div>
                    <p class="product-line__price cart-product__price">
                      <span
                        class="product-price__text"
                        :class="{'product-price__text--discount': entry.totalPriceWithoutDiscount > entry.totalPrice}"
                      >{{ formatPrice(entry.totalPrice) }}
                      </span>
                      <span
                        v-if="entry.totalPriceWithoutDiscount > entry.totalPrice"
                        class="product-price__text product-price__text--base"
                      >{{ formatPrice(entry.totalPriceWithoutDiscount)  }}</span>
                      <span class="product-price__text">Total</span>
                    </p>
                  </div>
                  <button
                    @click="onAddProductToCartClick(entry)"
                    :disabled="!entry.product.isPurchasable"
                    class="button button--primary button--add-to-cart"
                  >
                    <i class="icon-shopping-cart"></i>
                    Add to cart
                  </button>
                </div>
              </div>
            </tr>
          </tbody>
        </table>
      </template>
    </div>

    <ModalReorder
      v-if="showModalReorder"
      :orderData="orderDetailsData"
      :showModal="showModalReorder"
      @close="showModalReorder = false"
    />

    <ModalCart
      v-if="showModalCart"
      @close="closeCartModal"
      @addProduct="onAddToCartFromModalCart"
      :productEntry="productEntryData"
      :showModalCart="showModalCart"
    />

    <ModalInventory
      :inventoryData="inventoryData"
      :showModal="showModalInventory"
      @close="closeInventoryModal"
    />

    <loader v-if="isLoading" />
  </div>
</template>

<script>
import { mapMutations } from 'vuex'

import FormatMixin from '@/mixins/FormatMixin'
import ShippingStatusesMixin from '@/mixins/ShippingStatusesMixin'
import PageMixin from '@/mixins/PageMixin'
import ScreenWidthMixin from '@/mixins/ScreenWidthMixin'
import NavigationalMixin from '@/mixins/NavigationalMixin'
import AddToCartMixin from '@/mixins/AddToCartMixin'
import LoaderMixin from '@/mixins/LoaderMixin'
import AddressItem from '@/components/AddressItem'
import PaymentCard from '@/components/PaymentCard'
import ModalReorder from '@/components/modal/ModalReorder'
import ModalCart from '@/components/modal/ModalCart'
import ModalInventory from '@/components/modal/ModalInventory'

export default {
  name: 'OrderDetails',
  components: {
    PaymentCard,
    AddressItem,
    ModalReorder,
    ModalCart,
    ModalInventory
  },
  mixins: [
    PageMixin,
    FormatMixin,
    ShippingStatusesMixin,
    ScreenWidthMixin,
    NavigationalMixin,
    AddToCartMixin,
    LoaderMixin
  ],
  props: {
    orderDetailsData: {
      type: Object,
      requried: true
    },
    isReorderEnabled: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    productGroups () {
      const groups = {}
      this.orderDetailsData.entries.forEach(entry => {
        if (!groups[entry.lineStatus]) groups[entry.lineStatus] = {}

        if (entry.trackingItems) {
          const trackingIdLine = entry.trackingItems.map(el => el.trackingId).join(', ')
          if (!groups[entry.lineStatus][trackingIdLine]) groups[entry.lineStatus][trackingIdLine] = []
          groups[entry.lineStatus][trackingIdLine].push(entry)
        } else {
          if (!groups[entry.lineStatus].noTrackingId) groups[entry.lineStatus].noTrackingId = []
          groups[entry.lineStatus].noTrackingId.push(entry)
        }
      })
      return groups
    }
  },
  methods: {
    ...mapMutations('product', { setProductAnalyticsData: 'SET_PRODUCT_ANALYTICS_DATA' }),
    extractQuantityShipped (entry) {
      return entry.trackingItems
        ? entry.trackingItems.map(el => el.shippedQuantity).reduce((acc, cur) => acc + cur)
        : '-'
    },
    handleProductLinkClick (link) {
      this.setProductAnalyticsData({ referencePage: 'Order History' })
      this.navigateTo(link)
    },
    onAddProductToCartClick (entry) {
      this.withLoader(async () => {
        await this.addProductToCart({
          productData: entry.product,
          addToCartQty: entry.quantity,
          position: 0,
          pageName: 'Order History',
          searchQuery: ''
        })
      })
    },
    onAddToCartFromModalCart ({ productData, addToCartQty }) {
      this.withLoader(async () => {
        await this.addProductToCart({
          productData,
          addToCartQty,
          position: 0,
          pageName: 'Order History',
          searchQuery: ''
        })
      })
    }
  },
  data () {
    return {
      showModalReorder: false
    }
  }
}
</script>
