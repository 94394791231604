export default {
  methods: {
    withErrorOverlay (cb) {
      return cb().catch(e => {
        this.hasErrorOverlay = true
        throw e
      })
    }
  },
  data () {
    return {
      hasErrorOverlay: false
    }
  }
}
