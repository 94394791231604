<template>
  <Modal
    :isLoading="isLoading || !isInitialized"
    :showModal="showModalCart"
    @close="$emit('close')"
    class="modal-cart"
  >
    <template v-slot:header>
      <div>
        <i class="modal-cart__check icon-check-circle"></i>
        Added To Cart
      </div>
    </template>
    <template v-slot:body>
      <div class="modal-cart__product modal-product">
        <div class="modal-product__img-container">
          <img class="modal-product__img" :src="productEntry.image.src" :alt="productEntry.image.title" loading="lazy" />
        </div>
        <div class="modal-product__desc">
          <h4 class="modal-product__title text-h4" v-html="productEntry.title"></h4>
          <p class="modal-product__brand" v-html="`Catalog ID: ${productEntry.catalogId}`"></p>
          <p class="modal-product__quantity">Quantity: {{ productEntry.quantity }}</p>
          <div class="modal-product__price">
            <div class="modal-product__price-line" v-if="productEntry.quantity > 1">
              <ProductPrice :productData="productEntry" />
              <span class="modal-product__price-unit">Each</span>
            </div>
            <div class="modal-product__price-line">
              <ProductPrice v-if="priceDataForAddedQuantity" :productData="priceDataForAddedQuantity" />
              <span class="modal-product__price-unit">Total</span>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-cart__total" v-if="cartData">
        <p>
          Cart subtotal ({{ `${cartData.totalItems} ${cartData.totalItems > 1 ? 'items' : 'item'}` }}):
          {{ formatPrice(cartData.totalPrice) }}
        </p>
      </div>
    </template>
    <template v-slot:footer>
      <div class="modal-cart__actions">
        <button type="button" @click="$emit('close')" class="button button--primary">
          Continue Shopping
        </button>
        <router-link to="/cart/review-cart" type="button" class="button button--outlined">
          View Cart & Checkout
        </router-link>
      </div>
      <div class="modal-cart__related-products" v-if="relatedProductsList && relatedProductsList.length">
        <h4 class="text-h4">Related Products</h4>
        <ProductCarousel
          @addProductToCart="$emit('addProduct', $event)"
          :productsList="relatedProductsList"
          :itemsPerPage="[[0, 1], [768, 3]]"
          :scrollPerPage="true"
          :navigationEnabled="false"
          :paginationEnabled="true"
          :analyticsData="{referencePage: 'Related Products Add to Cart', enableImpressions: true}"
          :isAddToCartVisible="true"
          :isPriceVisible="true"
          carouselClass="carousel-dot-navigation"
          :class="{ 'stretch-carousel-product': !isIEOrSafari }"
        />
      </div>
    </template>
  </Modal>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import FormatMixin from '@/mixins/FormatMixin'
import UtilityMixin from '@/mixins/UtilityMixin'
import Modal from '@/components/modal/Modal'
import ProductPrice from '@/components/ProductPrice'
import ProductCarousel from '@/components/ProductCarousel'

export default {
  name: 'ModalCart',
  components: {
    Modal,
    ProductPrice,
    ProductCarousel
  },
  mixins: [FormatMixin, UtilityMixin],
  props: {
    productEntry: Object,
    showModalCart: Boolean,
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('cart', ['cartData']),
    priceDataForAddedQuantity () {
      if (!this.productEntry) return null
      return {
        clearancePrice: this.calculatePrice(this.productEntry.clearancePrice),
        employeePrice: this.calculatePrice(this.productEntry.employeePrice),
        listPrice: this.calculatePrice(this.productEntry.listPrice),
        affiliatePrice: this.calculatePrice(this.productEntry.affiliatePrice),
        discountPrice: this.calculatePrice(this.productEntry.discountPrice)
      }
    }
  },
  methods: {
    ...mapActions('product', ['getRelatedProducts']),
    calculatePrice (price) {
      return price ? (price * this.productEntry?.quantity).toFixed(2) : null
    }
  },
  async created () {
    this.relatedProductsList = await this.getRelatedProducts({
      productCode: this.productEntry?.sku,
      quantity: 3
    })
    this.isInitialized = true
  },
  data () {
    return {
      relatedProductsList: null,
      isInitialized: false
    }
  }
}
</script>
