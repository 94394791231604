import { detectIE, detectSafari } from '@/helpers'

export default {
  computed: {
    isIEOrSafari () {
      return detectSafari() || detectIE()
    },
    isIE () {
      return detectIE()
    },
    isTouchScreen () {
      return 'ontouchstart' in document.documentElement
    }
  },
  methods: {
    debounce (func, delay = 500) {
      let debounceTimer
      return function () {
        const context = this
        const args = arguments
        clearTimeout(debounceTimer)
        debounceTimer = setTimeout(() => func.apply(context, args), delay)
      }
    },
    getHeaderHeight () {
      return document.querySelector('#header')?.clientHeight || 0
    }
  }
}
