import { mapMutations } from 'vuex'

import PageTitleMixin from '@/mixins/PageTitleMixin'
import ErrorOverlayMixin from '@/mixins/ErrorOverlayMixin'

export default {
  mixins: [PageTitleMixin, ErrorOverlayMixin],
  computed: {
    pageCrumbs () {
      return []
    },
    pageTitle () {
      return this.$route.meta?.title
    },
    metaData () {
      return {}
    }
  },
  methods: {
    ...mapMutations('crumbs', { setCrumbsData: 'SET_CRUMBS_DATA' }),
    setMetaTags () {
      this.unsetMetaTags()

      if (this.metaData?.description) {
        const descriptionMetaTag = document.createElement('meta')
        descriptionMetaTag.id = this.descriptionMetaTagId
        descriptionMetaTag.name = 'description'
        descriptionMetaTag.content = this.metaData?.description
        document.head.prepend(descriptionMetaTag)
      }
    },
    unsetMetaTags () {
      if (this.metaData?.description) {
        const el = document.getElementById(this.descriptionMetaTagId)
        if (el) el.remove()
      }
    }
  },
  created () {
    this.setPageTitle(this.pageTitle)
  },
  mounted () {
    this.setCrumbsData(this.pageCrumbs)
  },
  data () {
    return {
      descriptionMetaTagId: 'descriptionMetaTag'
    }
  }
}
