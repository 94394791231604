<template>
  <Modal
    :showModal="showModal"
    @close="$emit('close')"
    class="modal-inventory"
  >
    <template v-slot:header>
      <div class="modal-inventory__title-wrap">
        Inventory Notice
      </div>
    </template>
    <template v-slot:body v-if="inventoryData">
      <template v-if="inventoryData.isClearance">
        <p class="modal-inventory__message" v-if="inventoryData.inventoryQty === 0">
          Unfortunately, this clearance item <strong v-html="inventoryData.productTitle"></strong> is no longer being stocked,
          and no inventory remains. It will be removed from the store shortly.
          Additional inventory is not available at this time; if inventory becomes available,
          it will be updated and added back into the store.
        </p>
        <p class="modal-inventory__message" v-else>
          Unfortunately, this clearance item <strong v-html="inventoryData.productTitle"></strong> is no longer
          being stocked enough to fulfill the requested quantity, though some inventory remains.
          We have added the maximum available amount of <strong>{{ inventoryData.inventoryQty }}</strong>
          for <strong v-html="inventoryData.productTitle"></strong> to your cart. Additional inventory is not available at this time.
        </p>
      </template>

      <p class="modal-inventory__message" v-else>
        This item currently has low inventory. While we are not able to immediately ship
        the complete quantity of the <strong v-html="inventoryData.productTitle"></strong> ordered,
        the order will be processed and we will send you an estimated shipping date once known.
      </p>
    </template>
    <template v-slot:footer>
      <div class="modal__actions">
        <button
          type="button"
          class="button button--primary"
          @click="$emit('close')"
        >
          Ok
        </button>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/modal/Modal'

export default {
  name: 'ModalInventory',
  components: {
    Modal
  },
  props: ['inventoryData', 'showModal']
}
</script>
