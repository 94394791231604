import shippingStatuses from '@/data/shipping-statuses-map'

export default {
  methods: {
    getShippingStatus (status) {
      // @TODO Confirm that EXPORTED is default status
      return shippingStatuses[status] || shippingStatuses.EXPORTED
    }
  }
}
