<template>
  <ul class="address-item">
    <li
      v-for="(item, n) in addressLinesList"
      :key="`${item}-${n}`"
      v-html="item"
      class="address-item__line"
    >
    </li>
  </ul>
</template>

<script>
import FormatMixin from '@/mixins/FormatMixin'

export default {
  mixins: [FormatMixin],
  props: {
    data: Object
  },
  computed: {
    addressLinesList () {
      return this.data && Object.keys(this.data).length !== 0
        ? [
          `${this.getFieldText(this.data.firstName)} ${this.getFieldText(this.data.lastName)}`.trim(),
          this.getFieldText(this.data.companyName),
          this.getFieldText(this.data.line1),
          this.getFieldText(this.data.line2),
          `${this.addComma(this.data.town)} ${this.getFieldText(this.data.region?.isocode)}`.trim(),
          this.getFieldText(this.data.postalCode),
          this.getFieldText(this.formatPhone(this.data.phone))
        ].filter(item => item)
        : null
    }
  },
  methods: {
    addComma (text) {
      return text ? `${text},` : ''
    },
    getFieldText (field) {
      return field || ''
    }
  }
}
</script>
